import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import LogoExtra from '../../images/logo-extra.png';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { ReactComponent as IconPolizza } from '../../images/icn_polizza.svg';
import { ReactComponent as IconFinancial } from '../../images/icn_rendicontazioni.svg';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import { ReactComponent as IconAffiliations } from '../../images/icn-incontro.svg';
import { ReactComponent as IconLogs } from '../../images/icn-edit.svg';
import { ReactComponent as IconCalendar } from '../../images/icn-calendar.svg';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import { ReactComponent as IconNetwork } from '../../images/icn-incontro.svg';
import {
  FINANCIAL_REPORTS_ALLOWED_TYPES,
  AFFILIATIONS_ALLOWED_TYPES,
  INVOICES_ALLOWED_TYPES,
  ROYALTIES_ALLOWED_TYPES,
  INSURANCES_ALLOWED_TYPES,
  ENERGY_USERS,
  CUSTOMERS_ALLOWED_TYPES,
  CUSTOMERS_CONDOS_ALLOWED_TYPES,
  ADMIN_TYPES,
  AGENT_TYPES,
  USER_ADMIN_TYPES,
  CERTIFIER_TYPE,
} from '../../App';
import './LinkMenu.scss';

function LinkMenu({ match }) {
  const [t] = useTranslation('PAGES');

  const { url } = match || {};
  const [{ isAdmin = false, userType = '', profile = {} } = {}] = useAuth();
  const { type: profileType = '', commercial = false } = profile;

  const getActive = (url = '', paths = []) => {
    return paths.some((path) => url.startsWith(path)) ? 'active' : '';
  };

  return (
    <>
      <div className="cont-logo-menu">
        <img src={LogoExtra} alt="Extra Franchising" />
      </div>
      <div className="cont-link-menu">
        {isAdmin && userType === 'SUPER_ADMIN' && (
          <Link to={'/dashboard'} className={getActive(url, ['/dashboard'])}>
            <IconDashboard className="hover-color" /> {t('DASHBOARD')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/profiles'}
            className={getActive(url, ['/profiles', '/profile-detail'])}
          >
            <IconRegistries className="hover-color" /> {t('REGISTRIES')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/condos'} className={getActive(url, ['/condos'])}>
            <IconBuilding className="hover-color" /> {t('PAGES:CONDOS')}
          </Link>
        )}

        {isAdmin && (
          <Link
            to={'/customer-extra-masters'}
            className={getActive(url, ['/customer-extra-masters'])}
          >
            <IconProfile className="hover-color" /> {t('PAGES:CUSTOMER_EXTRA')}
          </Link>
        )}

        {isAdmin && (
          <Link to={'/by-month'} className={getActive(url, ['/by-month'])}>
            <IconCalendar className="hover-color" />
            {t('PAGES:INVOICES_BY_MONTH')}
          </Link>
        )}

        {isAdmin &&
          (userType === 'SUPER_ADMIN' ||
            userType === USER_ADMIN_TYPES.SUPERVISOR) && (
            <Link
              to={'/my-emitted-iaf'}
              className={getActive(url, ['/my-emitted-iaf'])}
            >
              <IconInvoices
                className="hover-color"
                style={{ height: '23px' }}
              />
              {t('PAGES:IAF_EMITTED')}
            </Link>
          )}

        {isAdmin && userType !== USER_ADMIN_TYPES.SUPERVISOR_USER && (
          <Link
            to={'/sail-invoices'}
            className={getActive(url, ['/sail-invoices'])}
          >
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:SAIL_INVOICES')}
          </Link>
        )}

        {isAdmin && (userType === 'SUPER_ADMIN' || userType === 'SUPERVISOR') && (
          <Link
            to={'/overdue-invoices'}
            className={getActive(url, ['/overdue-invoices'])}
          >
            <IconInvoices className="hover-color" style={{ height: '23px' }} />{' '}
            {t('PAGES:OVERDUE_INVOICES')}
          </Link>
        )}

        {/* {isAdmin && (
          <Link to={'/iaf-15'} className={getActive(url, ['/iaf-15'])}>
            <IconInvoices className="hover-color" />
            {t('PAGES:CAP15')}
          </Link>
        )} */}

        {/* {isAdmin && (
          <Link
            to={'/invoices-15'}
            className={getActive(url, ['/invoices-15'])}
          >
            <IconInvoices className="hover-color" />
            {t('PAGES:INVOICES15')}
          </Link>
        )} */}

        {/* {isAdmin && (
          <Link to={'/iaf-10'} className={getActive(url, ['/iaf-10'])}>
            <IconInvoices className="hover-color" />
            {t('PAGES:IAF10')}
          </Link>
        )} */}

        {/* {isAdmin && (
          <Link
            to={'/invoices-10'}
            className={getActive(url, ['/invoices-10'])}
          >
            <IconInvoices className="hover-color" />
            {t('PAGES:INVOICES10')}
          </Link>
        )} */}

        {/*{isAdmin && (*/}
        {/*  <Link*/}
        {/*    to={'/report-profiles'}*/}
        {/*    className={getActive(url, ['/report-profiles'])}*/}
        {/*  >*/}
        {/*    <IconCalendar className="hover-color"/> /!*TODO CHANGE ICON*!/*/}
        {/*    {t('PAGES:REPORT')}*/}
        {/*  </Link>*/}
        {/*)}*/}

        {isAdmin && (
          <Link to={'/logs'} className={getActive(url, ['/logs'])}>
            <IconLogs className="hover-color" />
            {t('PAGES:LOGS')}
          </Link>
        )}

        {!isAdmin && (
          <Link to={'/my-profile'} className={getActive(url, ['/my-profile'])}>
            <IconRegistries className="hover-color" /> {t('MY_PROFILE')}
          </Link>
        )}

        {!isAdmin &&
          (ADMIN_TYPES.includes(profileType) ||
            profile.type === 'ADMIN_COLLAB' ||
            profile.type === 'CERTIFIER') && (
            <Link to={'/my-condos'} className={getActive(url, ['/my-condos'])}>
              <IconBuilding className="hover-color" /> {t('CONDOS')}
            </Link>
          )}

        {!isAdmin && CUSTOMERS_CONDOS_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/partner-condos'}
            className={getActive(url, ['/partner-condos'])}
          >
            <IconBuilding className="hover-color" /> {t('CONDOS')}
          </Link>
        )}

        {!isAdmin &&
          (AGENT_TYPES.includes(profileType) ||
            profileType === 'ADMIN_COLLAB') && (
            <Link
              to={'/customer-extra'}
              className={getActive(url, ['/customer-extra'])}
            >
              <IconProfile className="hover-color" style={{ height: '23px' }} />
              {t('PAGES:CUSTOMER_EXTRA')}
            </Link>
          )}

        {!isAdmin &&
          (ENERGY_USERS.includes(profileType) ||
            profileType === 'ADMIN_COLLAB') && (
            <Link to={'/contracts'} className={getActive(url, ['/contracts'])}>
              <IcnDocument className="hover-color" /> {t('CONTRACTS')}
            </Link>
          )}

        {!isAdmin && profileType === 'EON_ENERGY' && (
          <Link
            to={'/my-eon-condos'}
            className={getActive(url, ['/my-eon-condos'])}
          >
            <IconBuilding className="hover-color" /> {t('CONDOS')}
          </Link>
        )}

        {!isAdmin && FINANCIAL_REPORTS_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-financial-reports'}
            className={getActive(url, ['/my-financial-reports'])}
          >
            <IconFinancial className="hover-color" />
            {t('PAGES:FINANCIAL_REPORTS')}
          </Link>
        )}

        {!isAdmin && AFFILIATIONS_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-affiliations'}
            className={getActive(url, ['/my-affiliations'])}
          >
            <IconAffiliations
              className="hover-color"
              style={{ height: '23px' }}
            />
            {t('CONTACTS:AFFILIATIONS')}
          </Link>
        )}

        {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-invoices'}
            className={getActive(url, ['/my-invoices'])}
          >
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('INVOICES')}
          </Link>
        )}

        {/* {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-invs-15'} className={getActive(url, ['/my-invs-15'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('INVOICES15')}
          </Link>
        )} */}

        {!isAdmin && INVOICES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-by-month'}
            className={getActive(url, ['/my-by-month'])}
          >
            <IconCalendar className="hover-color" />
            {t('PAGES:INVOICES_BY_MONTH')}
          </Link>
        )}

        {!isAdmin &&
          (ROYALTIES_ALLOWED_TYPES.includes(profileType) ||
            profileType === 'ADMIN_COLLAB') && (
            <Link
              to={'/my-royalties'}
              className={getActive(url, ['/my-royalties'])}
            >
              <IconInvoices
                className="hover-color"
                style={{ height: '23px' }}
              />
              {t('PAGES:INVOICES_AND_PAYMENTS')}
            </Link>
          )}

        {!isAdmin &&
          profileType !== 'AGENCY' &&
          ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
            <Link
              to={'/my-studio-invoices'}
              className={getActive(url, ['/my-studio-invoices'])}
            >
              <IconInvoices
                className="hover-color"
                style={{ height: '23px' }}
              />
              {t('PAGES:STUDIO_INVOICES')}
            </Link>
          )}

        {!isAdmin &&
          ['EON_ENERGY', 'SUPPLIER', 'SUPPLIER_INS'].includes(profileType) && (
            <Link
              to={`/my-received-extra-invoice?year=${new Date().getFullYear()}`}
              className={getActive(url, ['/my-received-extra-invoice'])}
            >
              <IconInvoices
                className="hover-color"
                style={{ height: '23px' }}
              />
              {t('PAGES:EXTRA_INVOICE_RECEIVED')}
            </Link>
          )}

        {!isAdmin &&
          (ROYALTIES_ALLOWED_TYPES.includes(profileType) ||
            profileType === 'COMMERCIAL') && (
            <Link
              to={'/my-received-iaf'}
              className={getActive(url, ['/my-received-iaf'])}
            >
              <IconInvoices
                className="hover-color"
                style={{ height: '23px' }}
              />
              {t('PAGES:IAF_RECEIVED')}
            </Link>
          )}

        {/* {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-cap'} className={getActive(url, ['/my-cap'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:CAP15')}
          </Link>
        )} */}

        {/* {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-iafs'} className={getActive(url, ['/my-iafs'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:IAF10')}
          </Link>
        )} */}

        {/* {!isAdmin && ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
          <Link to={'/my-invs-10'} className={getActive(url, ['/my-invs-10'])}>
            <IconInvoices className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INVOICES10')}
          </Link>
        )} */}

        {!isAdmin &&
          (ROYALTIES_ALLOWED_TYPES.includes(profileType) ||
            profileType === 'ADMIN_COLLAB') && (
            <Link
              to={'/my-insurance-royalties'}
              className={getActive(url, ['/my-insurance-royalties'])}
            >
              <IconPolizza className="hover-color" style={{ height: '23px' }} />
              {t('PAGES:INSURANCES')}
            </Link>
          )}

        {!isAdmin && INSURANCES_ALLOWED_TYPES.includes(profileType) && (
          <Link
            to={'/my-insurances'}
            className={getActive(url, ['/my-insurances'])}
          >
            <IconPolizza className="hover-color" style={{ height: '23px' }} />
            {t('PAGES:INSURANCES')}
          </Link>
        )}

        {!isAdmin && CUSTOMERS_ALLOWED_TYPES.includes(profile.type) && (
          <Link
            to={'/my-customers'}
            className={getActive(url, ['/my-customers'])}
          >
            <IconProfile className="hover-color" /> {t('PAGES:CUSTOMER_EXTRA')}
          </Link>
        )}

        {!isAdmin && ADMIN_TYPES.includes(profileType) && (
          <Link
            to={'/my-collaborators'}
            className={getActive(url, ['/my-collaborators'])}
          >
            <IconRegistries
              className="hover-color"
              style={{ height: '23px' }}
            />
            {t('PAGES:COLLABORATORS')}
          </Link>
        )}

        {!isAdmin && profileType !== CERTIFIER_TYPE.CERTIFIER && (
          <Link to={'/albo'} className={getActive(url, ['/albo'])}>
            <IconRegistries className="hover-color" /> {t('ALBO')}
          </Link>
        )}
  
        {!isAdmin && ADMIN_TYPES.includes(profileType) && (
          <Link to={'/activities'} className={getActive(url, ['/activities'])}>
            <IcnDocument className="hover-color" /> {t('ACTIVITIES')}
          </Link>
        )}

        {!isAdmin && [...ADMIN_TYPES, 'SUPPLIER'].includes(profileType) && (
          <Link to={'/agreements'} className={getActive(url, ['/agreements'])}>
            <IcnDocument className="hover-color" /> {t('AGREEMENTS')}
          </Link>
        )}

        {commercial && (
          <>
            <Link to={'/network'} className={getActive(url, ['/network'])}>
              <IconNetwork className="hover-color" /> {t('COMMERCIAL:NETWORK')}
            </Link>
            <Link
              to={'/my-network-financial-reports'}
              className={getActive(url, ['/my-network-financial-reports'])}
            >
              <IconFinancial className="hover-color" />
              {t('PAGES:NETWORK_FINANCIAL_REPORTS')}
            </Link>
          </>
        )}

        {!isAdmin && ADMIN_TYPES.includes(profileType) && (
          <Link
            to={'/credit-calculation'}
            className={getActive(url, ['/credit-calculation'])}
          >
            <IconInvoices className="hover-color" />{' '}
            {t('PAGES:CREDIT_CALCULATION')}
          </Link>
        )}

        <Link to={'/info'} className={getActive(url, ['/info'])}>
          <IconProfile className="hover-color" /> {t('COMMON:INFO')}
        </Link>
      </div>
    </>
  );
}

export default withRouter(LinkMenu);
